import * as React from "react"
import Footer from "../components/landing/footer"
import Header from "../components/landing/header"
import Seo from "../components/seo"
import HeroSection from "../components/landing/hero.section"


const Page = () => (
    <>
        <Seo title={'Welcome !'}/>
        <main className="font-mono relative overflow-hidden h-screen">
            <Header/>
            <HeroSection/>
            <Footer/>
        </main>
    </>
)

export default Page
