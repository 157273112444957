import * as React from "react";
import MyPicture from '../../assets/images/me.jpeg'


const Section = () => (
    <section className="text-dark body-font">
        <div className="flex relative z-20 items-center">
            <div className="container mx-auto px-6 py-8 flex flex-col justify-between items-center relative">
                <div className="flex flex-col my-2">
                    <img src={MyPicture} className="rounded-full w-48 mx-auto grayscale-[75%]" alt="Mehdi"/>
                    <p className="text-3xl mt-8 text-center">
                        Hi, I'm Mehdi ✌🏻
                    </p>
                    <h2 className="max-w-3xl text-5xl md:text-6xl font-bold text-center py-2 my-4">
                        Building digital products, e-commerce websites, and mobile apps.
                    </h2>
                    <div className="flex flex-col items-center justify-start mt-6 sm:mt-8">
                        <div className="uppercase mb-8">
                            <a href="mailto:hi@nask.io" target="_blank" rel="noreferrer noopener"
                               className="py-2 px-4 bg-transparent border-2 border-dark text-dark hover:bg-dark hover:text-white text-md">
                                connect with me
                            </a>
                        </div>
                        <div className="uppercase font-black text-3xl flex items-center">
                            <svg viewBox="0 0 1792 1792"
                                 className="w-6 h-auto mx-auto fill-current">
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1664 1504v-768q-32 36-69 66-268 206-426 338-51 43-83 67t-86.5 48.5-102.5 24.5h-2q-48 0-102.5-24.5t-86.5-48.5-83-67q-158-132-426-338-37-30-69-66v768q0 13 9.5 22.5t22.5 9.5h1472q13 0 22.5-9.5t9.5-22.5zm0-1051v-24.5l-.5-13-3-12.5-5.5-9-9-7.5-14-2.5h-1472q-13 0-22.5 9.5t-9.5 22.5q0 168 147 284 193 152 401 317 6 5 35 29.5t46 37.5 44.5 31.5 50.5 27.5 43 9h2q20 0 43-9t50.5-27.5 44.5-31.5 46-37.5 35-29.5q208-165 401-317 54-43 100.5-115.5t46.5-131.5zm128-37v1088q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-1088q0-66 47-113t113-47h1472q66 0 113 47t47 113z">
                                </path>
                            </svg>
                            <span className="text-xs ml-3 mt-1">hi@nask.io</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default Section;
